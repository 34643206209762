import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate ,useParams} from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
const Product_details_source_city = () => {
  const { product_id,carrier_id } =  useParams();
  let navigate = useNavigate();
  const [countryList, setcountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const[country,setCountry] = useState({country_id:""})
  const[state,setState] = useState({state_id:""})
  const[city,setCity] = useState({city_id:""})
  const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:50,page:0,total_count:0,onload:true});
  const [productsource , setproductsource ]= useState([])
  const[stateLoading,setLoading]=React.useState({isLoading:true})
  const[cpscstate , setcpsc] = React.useState({carrier_name:[] , product_name:[] , source_city_name:[]})
  const[modals , setModals] = React.useState({show:false})
 

  React.useEffect(()=>{
    getsourcelist()
   
    sourceproductname()
  },[])

  const getCountryList = () => {


    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/country_list`;
    let sendData = {limit:300 , status:1};
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        // console.log("res ", res);           
        setcountryList(res.data.output)


    }).catch((e) => {


    });
}
const onChangeLocationGeneral = (e) => {
  console.log("e general name ",e.target.name);
   console.log("e general value ",e.target.value);
  
   setCountry({country_id:e.target.value})
    getStateList(e.target.value)
 
}


const getStateList = (country_id) => {


    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/state_list`;
    let sendData = { t_country_id: country_id, limit: 1000 };
    //  console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        // console.log("state ", res);           
        setStateList(res.data.output)


    }).catch((e) => {


    });
}

const onChangeLocationstate = (e) => {
  console.log("e general name ",e.target.name);
   console.log("e general value ",e.target.value);
   
   
 let values =   Object.assign({},e.target.value.split(',') )
 console.log("first",values[0])
 setState({state_id:values[0]})
 getCityList({state_id:values[0], country_id:values[1]})

 
}
const onChangeLocationcity = (e) => {
  console.log("e general name ",e.target.name);
   console.log("e general value ",e.target.value);
   
   
 
 setCity({city_id:e.target.value})


 
}
const getCityList = (value) => {

 console.log("value",value)
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/city_list`;
    let sendData = { state_id: value.state_id,country_id:value.country_id, limit: 1000 };
    console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        // console.log("city  ", res);           
        setCityList(res.data.output)


    }).catch((e) => {


    });
}
const add_source_city = () => {

  
     let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/product_source_city_add_api`;
     let sendData = {product_id:product_id , carrier_id:carrier_id ,source_country_id:country.country_id , source_state_id:state.state_id, source_city_id: city.city_id}
      console.log("bb", sendData);
     axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          console.log("city  ", res);           
       if(res.data.status=="success"){
        getsourcelist()
        setModals({...modals,show:false})
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"#206bc4",
          type: 'success',
          title: "Added Sucessfully",
          color:"white"
        });
       }
       else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"#206bc4",
          type: 'unsuccess',
          title: "Something Went Wrong",
          color:"white"
        });
       }
 
 
     }).catch((e) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        background:"#206bc4",
        type: 'unsuccess',
        title: "Something Went Wrong",
        color:"white"
      });
     
 
     });
 }

 const getsourcelist = (index=0) => {

 
     let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/product_source_city_list_api`;
     let sendData = { carrier_id:carrier_id, product_id:product_id, indexValue:index,
      limit:otherStates.rowsPerPage };
     console.log("bb", sendData);
     axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res ", res);   
      if(index==0  && otherStates.onload) {
        setOtherState({...otherStates,total_count:res.data.dataCount})         
}
setproductsource(res.data.output )
setLoading({isLoading:false})
 
     }).catch((e) => {
 
 
     });
 }


 const handlePageChange = (event,newPage) =>{
  console.log("newpage",newPage)
  setOtherState({...otherStates,page:newPage})
  //console.log("newPage ", newPage);
  getsourcelist(newPage);
  
  // searchUser(state.searchValue,newPage)
}
const handleChangeRowsPerPage = (event,newPage) => {
  console.log("event ", event);
  setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
 
}

const sourceproductname = ()=>{
  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/product_carrier_city_mode_name`;
  let sendData = {  product_id:product_id , carrier_id:carrier_id 
   }
  axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
  
           setcpsc({carrier_name:res.data.carrier_name , product_name: res.data.product_name })
         

      }).catch((e) => {
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
          })

          Toast.fire({
              background: "#206bc4",
              type: 'unsuccess',
              title: "Something went wrong",
              color: "white"
          });

      });
 }
  return (
    <div>
      <Sub_header/>
      <div  style={{marginLeft:"15px",marginRight:"15px"}}>
      <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Products</li>
                        </ol>
                    </nav>
                    

                    <div class="d-flex">
                        <div class="">
                            <h2 class="h4">{cpscstate.product_name.map((sub)=>(
                              sub.product_name
                            ))}</h2>
                        </div>
                        <div class="ps-2 ">
                            <h5><span class="badge rounded-pill bg-dark">Carrier: {cpscstate.carrier_name.map((sub)=>(
                              sub.carrier_name
                            ))} </span></h5>
                        </div>
                        <div class="ps-2 ">
                            <h5><span class="badge rounded-pill bg-warning">Mode: {cpscstate.product_name.map((sub)=>(
                              sub.mode
                            ))} </span></h5>
                        </div>
                    </div>
                </div>
                <div class="btn-toolbar mb-2 mb-md-0">
                    <a  onClick={()=>{setModals({...modals , show:true} );   getCountryList() }} class="btn btn-sm btn-gray-800 d-inline-flex align-items-center" >
                        <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                        New Source City Setup
                    </a>
                </div>
                {/* <!-- Modal  New Source city Create--> */}
                <Modal show={modals.show} onHide={()=>setModals({...modals,show:false})} >
                <Modal.Header closeButton>
                                    <h4 class=" modal-title">Setup New Source City for Pricing</h4>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <div class="modal-body">
      <div class="mb-3 mt-3">
          <label for="" class="form-label">Select Country:</label>
          <select class="form-select" name="country" onChange={(e)=>onChangeLocationGeneral(e)}>
            <option>Select</option>
            {countryList.map((item)=>{
            return (
              <option value={item.country_id}>{item.country_name}</option>
          )
           
})}
            </select>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Select State:</label>
          <select class="form-select"  name="state" onChange={(e)=>onChangeLocationstate(e)}>
            <option>Select</option>
            {stateList.map((item)=>{
            return (
              <option value={[item.state_id,item.country_id]}>{item.state_name}</option>
          )
           
})}
          
          </select>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Select City:</label>
          <select class="form-select" name="city" onChange={(e)=>onChangeLocationcity(e)}>
            <option >Select</option>
            {cityList.map((item)=>{
            return (
              <option value={item.city_id}>{item.city_name}</option>
          )
           
})}
          </select>
        </div>
      </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <div class="modal-footer">
        <button type="button" class="btn btn-primary" onClick={()=>add_source_city()}>Create & Save</button>
      </div>
                                    </Modal.Footer>
                </Modal>

            </div>
            
    </section>

    <section>
            <section>
                <ul class="nav nav-tabs justify-content-end">
                    <li class="nav-item">
                    <a class="nav-link " href={`/product_details/${product_id}/${carrier_id}`}>Price Chart</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link active" >Source City Management</a>
                    </li>
                </ul>
            </section>
        <div class="card border-0 shadow ">
       
          <div class="card-body">
                <section>
                    <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>City</th>
                            <th>State</th>
                            <th>Country</th>
                          </tr>
                        </thead>
                        {(stateLoading.isLoading) ?
                <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> :
                        <tbody>
                          {productsource.map((sub,index)=>(
                          <tr>
                            <td><a href={`/product_details_zone/${product_id}/${carrier_id}/${sub.source_city_id}`}>{sub.city_name}</a> </td>
                            <td>{sub.state_name}</td>
                            <td>{sub.country_name}</td>
                          </tr>
                          ))}
                        </tbody>
}
                      </table>
                      <TablePagination
                component="div"
                rowsPerPageOptions={[50,100,150,200]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
                </section>
            

            

                
          </div>

        </div>
    </section>
      </div>
    </div>
  )
}

export default Product_details_source_city
