import React, { useEffect } from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import '../../components/loader.css';
import TablePagination from '@mui/material/TablePagination';
import { Container, Row, Col, Form, Button, ProgressBar, ModalFooter, NavItem } from "react-bootstrap"
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'




const User_cod_wallet = () => {
    let navigate = useNavigate();
    const [state, setState] = React.useState({
        transaction_list: [],
        dataCount: 0,
        isLoading: true,
        dialogOpen: false,
        searchValue: "",
       

    })
    const[codbalance, setcodbalance]=React.useState( {  cod_available_balance:0})
    const search = window.location.search;
    // console.log("search -   ",search);
    const params = new URLSearchParams(search);
    let transaction= params.get('transaction')
 
   
  const [shipmentstate, setshipStatus] = React.useState({ status: null });
const[modals,setModals] = React.useState({show: false,transaction_id:"",shipment_id:"",created:"",date:"",merchant_id:"",full_name:"",payer_email:"",payer_id:"",paid_amount:"",currency_code:"",t_uid:"",payment_method:"",status:"",service_charge:"",online_id:"",remaining_amount:"",transporter_advance:"",admin_advance:"",invoice_id:""})
const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:50,page:0,total_count:0,onload:true});

const [searchfilters, setsearchFilters] = React.useState({ searchtitle: "" })
const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
const[customerstate, setCustomerState] = React.useState({customer:[]})
const[addState , setaddState] = React.useState({customer_id:"" , amount:"" , utr_id:"" , date:"" ,payment_mode:""})
console.log("addState === " , addState)

const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};

const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  React.useEffect(() => {
    dimension_detail();
    axios_get_api(shipmentstate.status)
    getUsersList()
   
   
  }, [])


    const axios_get_api = (status = null,index=0,onLoad) => {
        //  console.log("dstatus--->",shipment_status)
      
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user_cod_wallet_transaction_list`;
        let sendData = {
            find:"",
            current_status: status,
            indexValue: index,
            limit:otherStates.rowsPerPage
        };
       
        if(transaction!=null && transaction.length>0){
            sendData.find= transaction
        }
       
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            

            if(index==0  && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
            }
           
         setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
          
          console.log( "response",res);
       


        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })

    }
    React.useEffect(()=>{
       axios_get_api(null,0,true)
    },[])

    const searchUser = ( status,index=0) => {
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/transaction_find_detail`;
            
        let transporter_data= { indexValue:index, limit:otherStates.rowsPerPage, dstatus:1,transaction_id:"", status:status,};
        // console.log("pv",state.title)
        if ( searchfilters.searchtitle!="") {
           transporter_data.transaction_id=  searchfilters.searchtitle;
         }
         console.log("transporter",transporter_data)
       
    axios.post(full_api,transporter_data, { headers: exportValue.headers }).then(res => {        
//    setState({ ...state,searchValue:res.data.title})
   console.log( "response123",res);
   if(index==0 && otherStates.onload) {
    setOtherState({...otherStates,total_count:res.data.dataCount,onload:false})         
}  
   setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
   
   

   }).catch((e) => {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        
      })
       console.log("----error:   ", e);
   })          
  }

const stateHandler = (e) => {
    // state[key] = value
    if (e.target.name == "stransaction") {
      
            params.delete('transaction')
            transaction= null;
          setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
            //   navigate({ search: params.toString() })

        }
//    setsearchFilters({ ...searchfilters, searchtitle:searchfilters.searchtitle})
    setOtherState({...otherStates,onload:true})
}
const filter=()=>{
    transaction=null
    params.delete('transaction')
    if (searchfilters.searchtitle !== "") {           
        params.append('transaction', searchfilters.searchtitle)
    }
    searchUser(null,0)
    // setState({ ...state, isLoading: true })
    navigate({ search: params.toString() })

}
React.useEffect(()=>{
    if (transaction) {
        setsearchFilters({...searchfilters,searchtitle:transaction})
    }
// searchUser(null,0)
// axios_get_api(null,0,true);


},[])

    
    const shipmentFilter = (status) => {
        // console.log("action ",dstatus);
        setshipStatus({ ...shipmentstate, status: status })
      
        axios_get_api(status,0,true)
        
      }
   
   
     const handlePageChange = (event,newPage) =>{
        // console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        axios_get_api(shipmentstate.status,newPage);
        
        // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
        // console.log("event ", event);
        setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
       
    }

    const modalValues = (item) =>{
        console.log(item);
        setModals(item)
    }

    const getUsersList = () => {

        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_list_for_cod`;
        let sendData = {  };

       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res === " , res)
            setCustomerState({ ...customerstate, customer: res.data.output})
  }).catch((e) => {
        });
    }


    const pay_cod_to_customer = () => {
        
        if(addState.customer_id!="" && addState.amount!="" && addState.utr_id!="" && addState.date!='' && addState.payment_mode!=""){

        
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/pay_cod_to_customer`;
        let sendData = { customer_id:addState.customer_id , amount:addState.amount , utr_id:addState.utr_id , date:addState.date , payment_mode:addState.payment_mode};
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res === " , res)

            if(res.data.status==true){
                Swal.fire({
                    icon: 'success',
                    title: 'Good Job',
                    text: 'Success',
                    
                  })
                  window.location.reload();
                //   axios_get_api(shipmentstate.status)
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.data.message,
                    
                  })
                // console.log("----error:   ", e);
            }

            
            // setCustomerState({ ...customerstate, customer: res.data.output})
  }).catch((e) => {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        
      })
    // console.log("----error:   ", e);
        });
    }else{
      Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Fill All The Details',
                
              })
            // console.log("----error:   ", e);
    }
    }
    const handleChange=(e)=>{
        if(e.target.type=="text"){
            setaddState({...addState, [e.target.name]:e.target.value})
        }
        else{
            setaddState({...addState, [e.target.name]:e.target.value})
            check_available_balance(e.target.value)
        }
    }
    const check_available_balance = (customer) => {

        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/check_cod_available_balance`;
        let sendData = { customer_id: customer};

       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res === " , res)
            setcodbalance({ ...codbalance, cod_available_balance: res.data.cod_available_balance})
  }).catch((e) => {
        });
    }
  return (
    <div>
        
        <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Payments</li>
                        </ol>
                    </nav>
                    <h2 class="h4">Users COD wallet Transactions List</h2>
                </div>
                {/* <div class="btn-toolbar mb-2 mb-md-0">
                    <div class="input-group me-2 me-lg-3 fmxw-400">
                        <span class="input-group-text">
                            <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                            </svg>
                        </span>
                        <input type="text" class="form-control" placeholder="Search Transaction"/>
                    </div>
                </div> */}
                     <div class="col-12 col-md text-end">
        <a href="#" class="btn btn-sm btn-gray-800 d-inline-flex align-items-center" data-bs-toggle="modal" data-bs-target="#import_pincode_special">
            <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
            Pay to Customer
        </a>
    </div>
    <div class="modal" id="import_pincode_special">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">COD Payment Transfer</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
       <div class="mb-3 mt-3">
          <label for="" class="form-label">Select Customer: <span class="text-danger">*</span></label>
         <select className='form-select' name='customer_id' onChange={(e)=>handleChange(e)}>
            <option>Select</option>
           {customerstate.customer.map((sub)=>(
            <option value={sub.customer_id}>{sub.full_name}</option>
           ))}

         </select>
        </div>
        <div className='mb-3 mt-3'>
        <label for="" class="form-label">Available Balance: </label>
        <input  className='form-control' readOnly value={codbalance.cod_available_balance}/>
        </div>
        <div className='mb-3 mt-3'>
        <label for="" class="form-label">Amount to Transfer: <span class="text-danger">*</span></label>
        <input type="text" className='form-control' name='amount' placeholder='Enter Amount' onChange={(e)=>handleChange(e)}/>
        </div>
        <div className='mb-3 mt-3'>
        <label for="" class="form-label">UTR ID: <span class="text-danger">*</span></label>
        <input type="text" className='form-control' name='utr_id' placeholder='Enter UTR ID' onChange={(e)=>handleChange(e)}/>
        </div>
        <div className='mb-3 mt-3'>
        <label for="" class="form-label">Payment Mode: <span class="text-danger">*</span></label>
        <input type="text" className='form-control' name='payment_mode' placeholder='Enter Payment Mode' onChange={(e)=>handleChange(e)}/>
        </div>
        <div className='mb-3 mt-3'>
        <label for="" class="form-label">Select Date: <span class="text-danger">*</span></label>
        <input type="date" className='form-control' name='date' onChange={(e)=>handleChange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-primary"  
         onClick={() =>pay_cod_to_customer()}
        >Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
            </div>
            <section>
                <ul class="nav nav-tabs justify-content-end">
                    <li class="nav-item">
                      <a class={(shipmentstate.status == null)?"nav-link active":"nav-link"} href="#" onClick={()=>shipmentFilter(null)}>All</a>
                    </li>
                    <li class="nav-item">
                      <a class={(shipmentstate.status == 2)?"nav-link active":"nav-link"} href="#" onClick={()=>shipmentFilter(2)}>Success</a>
                    </li>
                    <li class="nav-item">
                      <a class={(shipmentstate.status == 1)?"nav-link active":"nav-link"} href="#" onClick={()=>shipmentFilter(1)}>Hold</a>
                    </li>
                    {/* <li class="nav-item">
                      <a class={(shipmentstate.status == 0)?"nav-link active":"nav-link"} href="#" onClick={()=>shipmentFilter(0)}>Pending</a>
                    </li> */}
                    
                </ul>
            </section>
            <div class="card card-body border-0 shadow table-wrapper table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Initiated</th>
                            <th class="border-gray-200">Transaction ID</th>
                            {/* <th class="border-gray-200">Gateway</th>						 */}
                            <th class="border-gray-200">Shipment #</th>						
                            <th class="border-gray-200">User</th>
                            <th class="border-gray-200">Mode</th>

                            <th class="border-gray-200">Amount</th>
                            <th class="border-gray-200">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <!-- Item --> */}
                        {state.transaction_list.map((item)=>
                        <tr>
                            <td>
                                <span class="fw-normal">
                                    <small><Moment format="MMM DD YYYY hh:mm">
                        {new Date(item.created*1000)}</Moment> <br/>
                                    {/* 5 months ago */}
                                    </small>
                                </span>
                            </td>
                            <td>
                                <a href="" data-bs-toggle="modal" data-bs-target="#modal-transaction-payment" class="fw-700 text-info" onClick={()=>modalValues(item)}>{item.transaction_id}</a>
                            </td>
                            {/* <td>
                                <span class="fw-bold">{item.payment_method} </span><br/>
                                     <small>{item.gateway_transaction_id}</small>
                            </td> */}
                            <td>
                                <a href={"/shipment_detail/"+item.shipment_id} class="fw-700 text-info">{item.shipment_id?item.shipment_id:"---------------"}</a>
                            </td>
                            <td>
                                <a href={"/customers_info/"+item.customer_id} class="fw-700 text-info">
                                   {item.full_name}
                                </a>
                            </td>   
                            <td>
                             
                                   {item.payment_method}
                               
                            </td>                        
                            <td>
                                <span class="fw-bold text-danger">
                                    {item.amount} {dimensionState.companyDetail.map((subscriber)=>( 
                  <>{subscriber.set_currency}</>
                 ))}
                                </span> <br/>
                                {/* <small>
                                    $15.00 + 10.00
                                </small> */}
                            </td>
                            <td>
                                {(item.payment_status == 0)?
                                <span class="badge rounded-pill bg-warning">Pending</span>:(item.payment_status == 1)?
                                <span class="badge rounded-pill bg-primary">Hold</span>:(item.payment_status ==2)?
                                <span class="badge rounded-pill bg-success">Success</span>:""
                                    }
                                </td>
                        </tr>
                        )}
                                                    
                    </tbody>
                </table>
                <TablePagination
                            component="div"
                            rowsPerPageOptions={[50,100,150,200]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                {/* <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-0">
                            <li class="page-item">
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">4</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">5</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
                </div> */}
            </div> 
                    

    </section>

    <section>
        {/* <!-- Modal Content --> */}
        <div class="modal fade" id="modal-transaction-payment" tabindex="-1" role="dialog" aria-labelledby="modal-transaction-payment" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="h6 modal-title">Transaction #{modals.transaction_id}</h2>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <table class="table">
                            <tbody>
                              <tr>
                                <td class="fw-700 text-dark">Date</td>
                                <td><Moment format="YYYY-MM-DD hh:mm">
                        {new Date(modals.created*1000)}</Moment></td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Transaction ID</td>
                                <td>{modals.transaction_id}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">User name</td>
                                <td>{modals.full_name}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Method</td>
                                <td>{modals.payment_method}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Send to email</td>
                                <td>{modals.email}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Amount</td>
                                <td>{modals.amount}</td>
                              </tr>
                              {/* <tr>
                                <td class="fw-700 text-dark">Charge</td>
                                <td>{modals.payment_method}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Payable</td>
                                <td>{modals.payment_method}</td>
                              </tr> */}
                              <tr>
                                <td class="fw-700 text-dark">Status</td>
                                <td>{(modals.payment_status == 0)?<span class="badge bg-warning">Pending</span>:(modals.payment_status == 1)?<span class="badge bg-success">Hold</span>:(modals.payment_status == 2)?<span class="badge bg-success">Success</span>:""}</td>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link text-gray-600 ms-auto" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        {/* // <!-- End of Modal Content --> */}
    </section>


    </div>
  )
}

export default User_cod_wallet