import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { Store } from "../src/redux/Store";

// import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Categories from "./views/Category/Categories";
import Subcategories from "./views/Category/Subcategories";
import Attributes from "./views/Category/Attributes";
import Left_panel from "./views/Left_panel";
import Index from "./views/Index";
import Login from "./views/Login";
import Forgot_password from "./views/Forgot_password";
import Sub_header from "./views/Sub_header";

import Country_list from "./views/location/Country_list";
import State_list from "./views/location/State_list";
import City_list from "./views/location/City_list";
import PostalCodes from "./views/location/PostalCodes";

import { Master_settings } from "./views/General_Settings/Master_settings";
import Payment_settings from "./views/General_Settings/Payment_settings";
import Social_settings from "./views/General_Settings/Social_settings";
import System_settings from "./views/General_Settings/System_settings";
import Smtp_settings from "./views/General_Settings/Smtp_settings";
import Shipments from "./views/Shipments/Shipments";
import Shipment_detail from "./views/Shipments/Shipment_detail";
import Print_receipts from "./views/Shipments/Print_receipts";
import Print_invoice from "./views/Shipments/Print_invoice";

import Payments from "./views/Payments/Payments";
import User_cod_wallet from "./views/Payments/User_cod_wallet";

import Transporter_detail from "./views/Transporter/Transporter_detail";
import Transporters from "./views/Transporter/Transporters";
import Transporter_wallet from "./views/Transporter/Transporter_wallet";
import Users from "./views/Users/Users";
import Agents from "./views/Users/Agents";

import User_detail from "./views/Users/User_detail";
import Vehicle_attributes from "./views/Vehicle/Vehicle_attributes";
import Email_template from "./views/Email_template/Email_template";
import Edit_global_email_template from "./views/Email_template/Edit_global_email_template";
import Edit_email_template from "./views/Email_template/Edit_email_template";
import Spam_report from "./views/Spam_Report/Spam_report";

import { Web_management } from "./views/Web_Management/Web_management";
import Home_content from "./views/Web_Management/Home_content";
import Cms_management from "./views/CMS_Management/Cms_management";
import My_profile from "./views/Profile/My_profile";
import Change_password from "./views/Profile/Change_password";
import Models_pop from "./views/Models_pop";
import Cms_edit from "./views/CMS_Management/Cms_edit";
import Testimonials from "./views/Web_Management/Testimonials";
import Vehicle_sub_attributes from "./views/Vehicle/Vehicle_sub_attributes";

import Ck_email_template from "./views/Ck_email_template";

import Admin_payments from "./views/Payments/Admin_payments";
import Transporter_payments from "./views/Payments/Transporter_payments";
import Add_group_value from "./views/Category/Add_group_value";
import Customers from "./views/Customers/Customers";
import Customers_info from "./views/Customers/Customers_info";
import Carrier_list from "./views/General_Settings/Carrier_list";
import Product_type from "./views/General_Settings/Product_type";
import Master from "./views/General_Settings/Master";
import Product from "./views/Product_management/Product";
import Add_product from "./views/Product_management/Add_product";
import Product_details from "./views/Product_management/Product_details";
import Product_details_source_city from "./views/Product_management/Product_details_source_city";
import Product_details_zone from "./views/Product_management/Product_details_zone";
import Import_process from "./views/Product_management/Import_process";
import Rate_calculator from "./views/Rate_calculator";
import Print_label from "./views/Shipments/Print_label";
import Master_new from "./views/General_Settings/Master_new";
import Log_view from "./views/Product_management/Log_view";
import Wallet_payment from "./views/Payments/Wallet_payment";
import Payment_gateway from "./views/Payments/Payment_gateway";
import Edit_product from "./views/Product_management/Edit_product";
import My_orders from "./views/Shipments/My_orders";
import Ship_1 from "./views/Shipments/Ship_1";
import My_address from "./views/Shipments/My_address";
import Ship_2 from "./views/Shipments/Ship_2";
import Package_list from "./views/Shipments/Package_list";
import Payment_details from "./views/Shipments/Payment_details";
import Summary from "./views/Shipments/Summary";
import Products from "./views/Product_management/Products";
import Product_detail from "./views/Product_management/Product_detail";
import Zone_management from "./views/Product_management/Zone_management";
import Special_zone from "./views/Product_management/Special_zone";
import Zone_metro_to_metro from "./views/Product_management/Zone_metro_to_metro";
import Special_zone_import_process from "./views/Product_management/Special_zone_import_process";
import Special_zone_log_view from "./views/Product_management/Special_zone_log_view";
import Metro_import_process from "./views/Product_management/Metro_import_process";
import Metro_zone_log_view from "./views/Product_management/Metro_zone_log_view";
import Servicable_pincode_import_process from "./views/General_Settings/Servicable_pincode_import_process";
import Servicable_pincodes from "./views/General_Settings/Servicable_pincodes";
import Servicable_pincodes_list from "./views/General_Settings/Servicable_pincodes_list";
import Weight_discrepancies from "./views/General_Settings/Weight_discrepancies";
import Weight_discrepancies_log from "./views/General_Settings/Weight_discrepancies_log";
import Customer_kyc_settings from "./views/Customer_kyc_settings";
import New_custromer_registration from "./views/New_custromer_registration";
import Sms_gateway_settings from "./views/Sms_gateway_settings";
import Tickets from "./views/Support_tickets/Tickets";
import Master_new_customer from "./views/General_Settings/Master_new_customer";
import Shipment_report from "./views/Reports/Shipment_report";
import Payment_report from "./views/Reports/Payment_report";
import Ship_3 from "./views/Shipments/Ship_3";
import Ship_4 from "./views/Shipments/Ship_4";
import Dispatcher from "./views/Users/Dispatcher";
import Assign_customer from "./views/Users/Assign_customer";
import Daily_shipment_summary from "./views/Daily_shipment_summary";
import Delivered_shipments from "./views/Shipments/Delivered_shipments";
import Undelivered_shipments from "./views/Shipments/Undelivered_shipments";
import Dispatcher_customers from "./views/Customers/Dispatcher_customers";
import Search_pincode from "./views/location/Search_pincode";
import Missing_pincodes from "./views/General_Settings/Missing_pincodes";
import Pending_kyc from "./views/Customers/Pending_kyc";
import Kyc_detail from "./views/General_Settings/Kyc_detail";
import Create_customer from "./views/Customers/Create_customer";
import Customer_invoices from "./views/Invoices/Customer_invoices";
import Invoice_vendor from "./views/Invoices/Invoice_vendor";
import Awb_inventory_settings from "./views/General_Settings/Awb_inventory_settings";
import Carrier_awb from "./views/General_Settings/Carrier_awb";

function App() {
  return (
    <Provider store={Store}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<Forgot_password />} />

          <Route exact path="/categories" element={<Categories />} />
          <Route
            exact
            path="/subcategories/:category_id"
            element={<Subcategories />}
          />
          <Route
            exact
            path="/attributes/:subcategory_id/:category_id"
            element={<Attributes />}
          />
          <Route
            exact
            path="/addgroupvalue/:subcategory_id/:category_id/:attribute_id"
            element={<Add_group_value />}
          />

          <Route exact path="/left_panel" element={<Left_panel />} />
          <Route exact path="/sub_header" element={<Sub_header />} />
          <Route exact path="/country_list" element={<Country_list />} />
          <Route
            exact
            path="/state_list/:country_id"
            element={<State_list />}
          />
          <Route
            exact
            path="/city_list/:country_id/:state_id"
            element={<City_list />}
          />
          <Route
            exact
            path="/postalcodes/:country_id/:state_id/:city_id"
            element={<PostalCodes />}
          />
          <Route exact path="/master_settings" element={<Master_settings />} />
          <Route
            exact
            path="/payment_settings"
            element={<Payment_settings />}
          />
          <Route exact path="/social_settings" element={<Social_settings />} />
          <Route exact path="/system_settings" element={<System_settings />} />
          <Route exact path="/smtp_settings" element={<Smtp_settings />} />
          <Route exact path="/shipments" element={<Shipments />} />
          <Route exact path="/orders" element={<My_orders />} />

          <Route
            exact
            path="/shipment_detail/:invoice_id"
            element={<Shipment_detail />}
          />
          <Route exact path="/payments" element={<Payments />} />
          <Route exact path="/user_cod_wallet" element={<User_cod_wallet />} />
          <Route exact path="/wallet_payments" element={<Wallet_payment />} />
          <Route
            exact
            path="/payment_gateway_payments"
            element={<Payment_gateway />}
          />

          <Route exact path="/admin_payments" element={<Admin_payments />} />
          <Route
            exact
            path="/transporter_payments"
            element={<Transporter_payments />}
          />

          <Route
            exact
            path="/transporter_detail/:transporter_id"
            element={<Transporter_detail />}
          />
          <Route exact path="/transporters" element={<Transporters />} />
          <Route
            exact
            path="/transporter_wallet"
            element={<Transporter_wallet />}
          />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/agents" element={<Agents />} />

          <Route exact path="/user_detail/:user_id" element={<User_detail />} />
          <Route
            exact
            path="/vehicle_attributes"
            element={<Vehicle_attributes />}
          />
          <Route
            exact
            path="/vehicle_sub_attributes/:category_id"
            element={<Vehicle_sub_attributes />}
          />

          <Route exact path="/email_template" element={<Email_template />} />
          <Route
            exact
            path="/edit_global_email_template"
            element={<Edit_global_email_template />}
          />
          <Route
            exact
            path="/edit_email_template/:email_template"
            element={<Edit_email_template />}
          />
          <Route exact path="/spam_report" element={<Spam_report />} />
          <Route exact path="/tickets" element={<Tickets />} />
          <Route exact path="/web_settings" element={<Web_management />} />
          <Route exact path="/home_content" element={<Home_content />} />
          <Route exact path="/cms_settings" element={<Cms_management />} />
          <Route exact path="/my_profile" element={<My_profile />} />
          <Route exact path="/change_password" element={<Change_password />} />
          <Route exact path="/models_pop" element={<Models_pop />} />
          <Route exact path="/edit_cms/:cms_id" element={<Cms_edit />} />
          <Route exact path="/testimonial" element={<Testimonials />} />
          <Route exact path="/ckemail" element={<Ck_email_template />} />
          <Route exact path="/customers" element={<Customers />} />
          <Route
            exact
            path="/missing_pincodes"
            element={<Missing_pincodes />}
          />

          <Route
            exact
            path="/delivered_shipments"
            element={<Delivered_shipments />}
          />
          <Route exact path="/search_pincode" element={<Search_pincode />} />
          <Route
            exact
            path="/dispatcher_customers"
            element={<Dispatcher_customers />}
          />

          <Route
            exact
            path="/undelivered_shipments"
            element={<Undelivered_shipments />}
          />

          <Route
            exact
            path="/customers_info/:customer_id"
            element={<Customers_info />}
          />
          <Route exact path="/carrier_list" element={<Carrier_list />} />
          <Route exact path="/product_type" element={<Product_type />} />
          <Route exact path="/master" element={<Master />} />
          <Route exact path="/master_new" element={<Master_new />} />

          <Route
            exact
            path="/print_receipts/:shipment_id"
            element={<Print_receipts />}
          />
          <Route
            exact
            path="/print_invoice/:shipment_id"
            element={<Print_invoice />}
          />
          <Route
            exact
            path="/print_label/:shipment_id"
            element={<Print_label />}
          />

          <Route exact path="/product" element={<Product />} />
          <Route exact path="/invoices" element={<Customer_invoices />} />
          <Route exact path="/invoice_detail" element={<Invoice_vendor />} />

          <Route exact path="/products/:customer_id" element={<Products />} />

          <Route exact path="/add_product" element={<Add_product />} />
          <Route
            exact
            path="/edit_product/:product_id/:carrier_id"
            element={<Edit_product />}
          />
          <Route
            exact
            path="/product_detail/:customer_id/:product_id/:carrier_id"
            element={<Product_detail />}
          />

          <Route
            exact
            path="/product_details/:product_id/:carrier_id"
            element={<Product_details />}
          />
          <Route
            exact
            path="/product_details_source_city/:product_id/:carrier_id"
            element={<Product_details_source_city />}
          />
          <Route
            exact
            path="/product_details_zone/:product_id/:carrier_id/:source_city_id"
            element={<Product_details_zone />}
          />
          <Route
            exact
            path="/import_process/:product_id/:carrier_id/:source_city_id"
            element={<Import_process />}
          />
          <Route
            exact
            path="/log_view/:product_id/:carrier_id/:source_city_id"
            element={<Log_view />}
          />

          <Route exact path="/zone_calculator" element={<Rate_calculator />} />
          <Route exact path="/ship_1" element={<Ship_1 />} />
          <Route exact path="/ship_2" element={<Ship_2 />} />
          <Route exact path="/package_list" element={<Package_list />} />
          <Route exact path="/payment_details" element={<Payment_details />} />
          <Route
            exact
            path="/weight_discrepancies"
            element={<Weight_discrepancies />}
          />
          <Route
            exact
            path="/weight_discrepancies_log/:record_id"
            element={<Weight_discrepancies_log />}
          />
          <Route
            exact
            path="/customer_kyc_settings"
            element={<Customer_kyc_settings />}
          />
          <Route
            exact
            path="/new_customer_registration"
            element={<New_custromer_registration />}
          />
          <Route
            exact
            path="/sms_gateway_settings"
            element={<Sms_gateway_settings />}
          />

          <Route exact path="/address_book" element={<My_address />} />
          <Route
            exact
            path="/summary/:record_id/:customer_id"
            element={<Summary />}
          />
          <Route exact path="/zone_list" element={<Zone_management />} />
          <Route
            exact
            path="/special_zone/:zone_id"
            element={<Special_zone />}
          />
          <Route
            exact
            path="/metro_to_metro/:zone_id"
            element={<Zone_metro_to_metro />}
          />
          <Route
            exact
            path="/special_zone_import_process/:zone_id"
            element={<Special_zone_import_process />}
          />
          <Route
            exact
            path="/special_zone_log_view/:zone_id/:record_id"
            element={<Special_zone_log_view />}
          />
          <Route
            exact
            path="/metro_import_process/:zone_id"
            element={<Metro_import_process />}
          />
          <Route
            exact
            path="/metro_log_view/:zone_id/:record_id"
            element={<Metro_zone_log_view />}
          />
          <Route
            exact
            path="/servicable_pincode_import_process/:carrier_id"
            element={<Servicable_pincode_import_process />}
          />
          <Route
            exact
            path="/servicable_carriers"
            element={<Servicable_pincodes />}
          />
          <Route
            exact
            path="/servicable_pincodes/:carrier_id"
            element={<Servicable_pincodes_list />}
          />
          <Route
            exact
            path="/master_new_customer/:customer_id"
            element={<Master_new_customer />}
          />
          <Route exact path="/shipment_report" element={<Shipment_report />} />
          <Route exact path="/payment_report" element={<Payment_report />} />
          <Route exact path="/ship_3" element={<Ship_3 />} />
          <Route exact path="/ship_4" element={<Ship_4 />} />
          <Route exact path="/dispatcher" element={<Dispatcher />} />
          {/* <Route exact path="/create_customer" element={<Create_customer />} /> */}

          <Route exact path="/pending_kyc" element={<Pending_kyc />} />
          <Route
            exact
            path="/carrier_awb/:carrier_id"
            element={<Carrier_awb />}
          />

          <Route
            exact
            path="/awb_inventory"
            element={<Awb_inventory_settings />}
          />

          <Route
            exact
            path="/kyc_detail/:customer_id"
            element={<Kyc_detail />}
          />

          <Route
            exact
            path="/assign_customer/:dispatcher_id"
            element={<Assign_customer />}
          />
          <Route
            exact
            path="/daily_shipment_summary"
            element={<Daily_shipment_summary />}
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
