import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Carrier_awb = () => {
   let carrier_id = useParams()
   console.log("carrier_id == " ,carrier_id)
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:50,page:0,total_count:0,onload:true});
    const [carrierList, setcarrierList] = useState([]);
    const [unusedcount , setUnusedCount] = useState(0)
    const [usedcount , setUsedCount] = useState(0)
    const [searchTerm, setSearchTerm] = useState("");
const [filteredCarrierList, setFilteredCarrierList] = useState([]);

   const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
  
    useEffect(() => {
        getCarrierList();
    }, [])

    const getCarrierList = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_awb_inventory`;
        let sendData = {  carrier_id:carrier_id.carrier_id  };
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
             setcarrierList(res.data.output)
             if (res.data.output.length > 0) {
                let awbList = res.data.output[0].awb;
        
                let unusedCount = awbList.filter(awb => !awb.shipment_id || awb.shipment_id === "").length;
                let usedCount = awbList.filter(awb => awb.shipment_id && awb.shipment_id !== "").length;
        
                setcarrierList(res.data.output);
                setFilteredCarrierList(res.data.output); // Set the filtered list initially
                setUnusedCount(unusedCount);
                setUsedCount(usedCount);
              }


        }).catch((e) => {


        });
    }

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
    }
  
    const handleSearch =()=>{
        console.log("yes")
        if (searchTerm == "") {
            setFilteredCarrierList(carrierList);
          } else {
            const filteredList = filteredCarrierList.filter(item =>
              item.awb.some(awb => awb.customer_code.includes(searchTerm))
            );
            console.log("filteredList = =",filteredList)
            setFilteredCarrierList(filteredList);
            let unusedCount = filteredList.filter(awb => !awb.shipment_id || awb.shipment_id === "").length;
            let usedCount = filteredList.filter(awb => awb.shipment_id && awb.shipment_id !== "").length;
            setUnusedCount(unusedCount);
            setUsedCount(usedCount);
          }
        };
      
        const deleteConfirm = (awb_number) => {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                //console.log("result  ",result);
                if (result.isConfirmed) {
                    // setIsLoading(true)
                    Delete_awb(awb_number)
                }
            })
    
         }
         const Delete_awb = (awb_number) => {
            try {
                
                    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_awb_delete`;
                    let bodyFormData = { awb_number: awb_number, carrier_id: carrier_id.carrier_id}
    
                    console.log("bb", bodyFormData);
                    axios.post(full_api, bodyFormData, { headers: exportValue.headers }).then((res) => {
                        console.log("res ", res);
                         
                            getCarrierList();
                        if (res) {
                            Swal.fire(
                                'Good job!',
                                ' Deleted Successfully !',
                                'success'
                            )
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!',
    
                            })
                        }
    
                        // navigate('/categories')
                        // let newCategory = res.data.category;
                        //setState(state.concat([newCategory]))
                    }).catch((e) => {
    
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
    
                        })
                    });
               
            } catch (e) {
                console.log(e);
            }
    
        }
    
  return (
    <div>
           <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
      
    

<div class="row py-4">
    <div class="col-12 col-md-3">
        
        <h2 class="h4">Awb Inventory Carriers</h2>
    </div>
    {/* <div class="col-12 col-md-3">
     <h5>Unused Awb: {unusedcount}</h5> 
   

    </div>
    <div class="col-12 col-md-3">
    
     <h5>Used Awb: {usedcount}</h5> 

    </div> */}
     <div class="col-12 col-md">
                <div class="row">
                    <div class="col-12 col-md-6">
                    <div class="col-12 col-md">
                        <div class="input-group me-2 me-lg-3 fmxw-400">
                      
                            <input type="text" class="form-control" placeholder="Search Customer Code" onChange={(e)=>handleSearchChange(e)} />
                            <span class="input-group-text" >
                                <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" onClick={(e)=>handleSearch()}>
                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" ></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-md-3">
                    <h5>Unused AWB: {unusedcount}</h5>
     
                    </div>
                    <div class="col-12 col-md-3">
                    <h5>Used AWB: {usedcount}</h5>
                    </div>
                   
                </div>
            </div>
  
</div>


        
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead>
                    <tr>
                        <th class="border-gray-200">#</th>
                        <th class="border-gray-200">Customer Code</th>	
                        <th class="border-gray-200">AWb Number</th>
                        <th class="border-gray-200">Shipment ID</th>
                        <th class="border-gray-200">Action</th>

                       
                    </tr>
                </thead>
                {filteredCarrierList.length>0?
                filteredCarrierList[0].awb.map((sub,index)=>(
                <tbody>
                  
                    <tr>
                        <td>
                            {index+1}
                        </td>
                        <td>
                        <strong>{sub.customer_code}</strong>
                        </td>                        
                        <td>
                            <strong>{sub.awb_number}</strong>
                        </td>                        
                        <td class="">
                        <strong>{sub.shipment_id?sub.shipment_id:""}</strong>
                        </td>
                    
                      <td>
                      
                      <div className="btn-group">
                                                    <button className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="icon icon-sm">
                                                            <span className="fa fa-ellipsis-h icon-dark"></span>
                                                        </span>
                                                        <span className="visually-hidden">Toggle Dropdown</span>
                                                    </button>
                                                    <div className="dropdown-menu py-0">
                                                        
                                                        {/* <a className="dropdown-item" onClick={() => edit_city(item)}><span className="fa fa-edit me-2"></span>Edit</a> */}
                                                        <a className="dropdown-item text-danger rounded-bottom" onClick={() => deleteConfirm(sub.awb_number)}><span className="fa  fa-trash me-2"></span>Remove</a>
                                                    </div>
                                                </div>
                      </td>
                    </tr>
                                                
                </tbody>
                )):""}
            </table>
           
        </div> 
                

</section>




    </div>
  )
}

export default Carrier_awb
